import React from "react"
import Title from "../title"
import styles from "../../css/services.module.css"
import services from "../../constants/services"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Services = () => {
  return (
    <section className={styles.services}>
      <Title title="Unser" subtitle="Service" />
      <div className={styles.center}>
        {services.map((item, index) => {
          return (
            <AnchorLink key={index} to={`/service#${item.id}`}>
              <article className={styles.service}>
                <span>{item.icon}</span>
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </article>
            </AnchorLink>
          )
        })}
      </div>
    </section>
  )
}

export default Services
