import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import Banner from "../components/banner"
import About from "../components/home/about"
import Services from "../components/home/services"
import Partners from "../components/home/partners"
import StyledHero from "../components/styledHero"
import SEO from "../components/seo"

export default ({ data, location }) => (
  <Layout location={location.pathname}>
    <SEO title="Home" />
    <StyledHero home="true" img={data.defaultBcg.childImageSharp.fluid}>
      <Banner
        title="#VsCovidUnited"
        info="#VsCovidUnited ist eine Hilfsinitiative, die durch das gezielte Zusammenbringen von pro bono Angeboten und Nachfrage einen Beitrag leisten will, die Wirtschaft und die Marktteilnehmer besser durch die Corona-Krise zu bringen."
      >
        <AniLink fade to="/join" className="btn-white">
          Mitmachen!
        </AniLink>
      </Banner>
    </StyledHero>
    <About />
    <Partners />
    <Services />
  </Layout>
)
export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
