import React from "react"
import {
  FaEdit,
  FaUserFriends,
  FaObjectGroup,
  FaSearch,
  FaHandHoldingUsd,
  FaHandsHelping,
  FaComments,
} from "react-icons/fa"

export default [
  {
    id: "registration",
    icon: <FaEdit />,
    title: "Erfassung",
    text: "Erfassung und Spezifikation von Hilfegesuchen und Hilfeangeboten",
  },
  {
    id: "matching",
    icon: <FaUserFriends />,
    title: "Zusammenführung",
    text: "Systematische Zusammenführung von Angebot und Nachfrage",
  },
  {
    id: "grouping",
    icon: <FaObjectGroup />,
    title: "Bündelung",
    text:
      "Die Bündelung verschiedener Hilfsangebote für dediziert nachgefragte Projekte",
  },
  {
    id: "search",
    icon: <FaSearch />,
    title: "Aktive Suche",
    text:
      "Aktive Suche nach Anbietern und Nachfragern, wo sich Angebot und Nachfrage nicht decken",
  },
  {
    id: "realisation",
    icon: <FaHandHoldingUsd />,
    title: "Finanzielle Hilfe",
    text: "Umsetzung von finanziellen Stützungsmaßnahmen",
  },
  {
    id: "offer",
    icon: <FaHandsHelping />,
    title: "Angebot",
    text: "Angebot unmittelbarer Unterstützungsleistungen",
  },
  {
    id: "public",
    icon: <FaComments />,
    title: "Öffentlichkeitsarbeit",
    text: "Tue Gutes und rede darüber",
  },
]
